import React, { useRef, useState } from 'react';
import css from './businessFormContainer.module.css';
import { createInstance } from '../../util/sdkLoader';
import config from '../../config';
import { withRouter } from 'react-router-dom';
import { storableError } from '../../util/errors';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
import { SignupForm } from '../../forms';
import { compose } from 'redux';

const requiredFields = [
  'firstName',
  'lastName',
  'email',
  'password',
  'phoneNumber',
  'isProvider',
  'bname',
];
const MIN_PWD_LENGTH = 8;
const MAX_PWD_LENGTH = 256;

function BusinessFormContainer(props) {
  const { history, location } = props;
  const [showPwd, setShowPwd] = useState(false);
  const [formValues, setFormValues] = useState({ isProvider: 'yes', bname: '' });
  const [formSubmitInProgress, setFormSubmitInProgress] = useState(false);
  const [showPwdHelperMsg, setShowPwdHelperMsg] = useState(false);
  const [showNumberHelperMsg, setShowNumberHelperMsg] = useState(false);
  const [showEmailTakenErr, setShowEmailTakenErr] = useState(false);
  const [showGeneralErr, setShowGeneralErr] = useState(false);

  const sdkInstance = useRef(createInstance({ clientId: config.sdk.clientId }));

  const updateForm = e => {
    const fieldname = e.target.name;
    let fieldvalue = e.target.value;
    if (fieldname === 'phoneNumber') {
      const strtIndx = fieldvalue.indexOf('+');
      const endIndx = fieldvalue.lastIndexOf('+');
      fieldvalue = fieldvalue.slice(0, 13).replace(/[^+0-9]/, '');
      if (strtIndx !== 0) fieldvalue = fieldvalue.replace(/\+/g, '');
      if (strtIndx !== endIndx)
        fieldvalue = fieldvalue.substring(0, endIndx) + fieldvalue.substring(endIndx + 1);
    }
    if (fieldname === 'password') togglePwdHelperMsg(true, fieldvalue);
    if (fieldname === 'phoneNumber') toggleNumberHelperMsg(true, fieldvalue);
    let bname = fieldname === 'isProvider' && fieldvalue.toLowerCase() === 'yes' ? '' : 'default';
    setShowEmailTakenErr(false);
    setShowGeneralErr(false);
    setFormValues(formValues => ({
      ...formValues,
      [fieldname]: fieldvalue,
      ...(fieldname === 'isProvider' && { bname }),
    }));
  };

  const togglePwdHelperMsg = (isFocus = true, value = '') => {
    const fieldvalue = !!value ? value : formValues.password;
    if (isFocus) {
      if (!!fieldvalue) {
        if (fieldvalue.length < MIN_PWD_LENGTH || fieldvalue.length > MAX_PWD_LENGTH) {
          setShowPwdHelperMsg(true);
        } else {
          setShowPwdHelperMsg(false);
        }
      } else {
        setShowPwdHelperMsg(true);
      }
    } else setShowPwdHelperMsg(false);
  };

  const toggleNumberHelperMsg = (isFocus = true, value = '') => {
    const fieldvalue = !!value ? value : formValues.phoneNumber;
    if (isFocus) {
      if (!!fieldvalue) {
        if (!fieldvalue.includes('+')) {
          setShowNumberHelperMsg(true);
        } else {
          setShowNumberHelperMsg(false);
        }
      } else {
        setShowNumberHelperMsg(true);
      }
    } else setShowNumberHelperMsg(false);
  };

  const handleFormSubmit = e => {
    e.preventDefault();
    if (!formSubmitInProgress) {
      const formKeys = Object.keys(formValues);
      const hasAllRequiredFields = requiredFields.every(
        field => formKeys.includes(field) && !!formValues[field]
      );
      const hasValidPhoneNumber = formValues.phoneNumber?.includes('+');
      const hasValidPassword =
        formValues.password?.length > MIN_PWD_LENGTH &&
        formValues.password?.length < MAX_PWD_LENGTH;

      if (hasAllRequiredFields && hasValidPhoneNumber && hasValidPassword) {
        setFormSubmitInProgress(true);
        const { phoneNumber, isProvider, bname, ...rest } = formValues;
        const isUserProvider = isProvider.toLowerCase() === 'yes';
        const queryParams = {
          ...rest,
          publicData: { phoneNumber, isProvider: true, ...(isUserProvider && { bname }) },
        };
        const urlParams = { expand: true };
        sdkInstance.current.currentUser
          .create(queryParams, urlParams)
          .then(res => {
            return sdkInstance.current.login({ username: rest.email, password: rest.password });
          })
          .then(res => {
            setFormSubmitInProgress(false);
            window.location.href = '/';
          })
          .catch(err => {
            const storableErr = storableError(err);
            storableErr.apiErrors.forEach(er => {
              if (er.code === 'email-taken') {
                setShowEmailTakenErr(true);
              }
            });
            setShowGeneralErr(storableErr.apiErrors.length <= 0);
            setFormSubmitInProgress(false);
            console.log('err', storableErr);
          });
      } else setFormSubmitInProgress(false);
    }
  };

  return (
    <div className={css.mainContainer}>
      <h3>Get started today and spend more time doing what you love.</h3>
      <span className={css.subHeading}>
        FREE trial. There is no setup fee. No minimum or maximum number of enquiries or bookings
        during your trial. Stop listing at any time.
      </span>
      <form method="POST" action="/" className={css.businessForm} onSubmit={handleFormSubmit}>
        {/* <div className={css.formWrapper}>
          <div className={css.nameContainer}>
            <label htmlFor="firstName">
              First Name
              <input
                type="text"
                id="firstName"
                name="firstName"
                placeholder="First Name"
                value={formValues.firstName}
                onChange={updateForm}
              />
            </label>
            <label htmlFor="lastName">
              Last Name
              <input
                type="text"
                id="lastName"
                name="lastName"
                placeholder="Last Name"
                value={formValues.lastName}
                onChange={updateForm}
              />
            </label>
          </div>
          <label htmlFor="email">
            Work Email
            <input
              type="email"
              id="email"
              name="email"
              placeholder="jane@gmail.com"
              value={formValues.email}
              onChange={updateForm}
            />
            {showEmailTakenErr && (
              <span className={css.emailErrContainer}>
                User with the given email already exists.
              </span>
            )}
          </label>
          <label htmlFor="password">
            Password
            <input
              type={showPwd ? 'text' : 'password'}
              id="password"
              name="password"
              value={formValues.password}
              onChange={updateForm}
              placeholder="Create your password"
              onFocus={() => togglePwdHelperMsg()}
              onBlur={() => togglePwdHelperMsg(false)}
            />
            <span
              role="button"
              className={css.showHidePwd}
              onClick={() => setShowPwd(showPwd => !showPwd)}
            >
              <img src={eyeIcon} alt="" />
            </span>
            {showPwdHelperMsg && (
              <span className={css.pwdHelperContainer}>
                Password must be between {MIN_PWD_LENGTH} and {MAX_PWD_LENGTH} characters.
              </span>
            )}
          </label>
          <label htmlFor="phoneNumber">
            Phone Number
            <input
              type="text"
              id="phoneNumber"
              name="phoneNumber"
              placeholder="Your phone number"
              onBlur={() => {
                toggleNumberHelperMsg(false);
              }}
              value={formValues.phoneNumber}
              onChange={updateForm}
              onFocus={() => toggleNumberHelperMsg()}
            />
            {showNumberHelperMsg && (
              <span className={css.numberHelperContainer}>
                Phone number must contain country code.
              </span>
            )}
          </label>
          {formValues.isProvider.toLowerCase() === 'yes' && (
            <label htmlFor="bname">
              Business Name
              <input
                type="text"
                id="bname"
                name="bname"
                placeholder="Example Business"
                value={formValues.bname}
                onChange={updateForm}
              />
            </label>
          )}
          <label htmlFor="hearAboutUs" className={css.selectLabel}>
            How'd you hear about us?
            <select id="hearAboutUs" name="hearAboutUs">
              <option hidden selected>
                As marketing people we'd love to know...
              </option>
              <option>Facebook</option>
              <option>Twitter</option>
              <option>Instagram</option>
              <option>Other sources</option>
            </select>
          </label>
          <Button type="submit" inProgress={formSubmitInProgress} disabled={formSubmitInProgress}>
            List My Business
          </Button>
          {showGeneralErr && (
            <span className={css.generalErrContainer}>
              Something went wrong. Please try again after some time or refresh the page.
            </span>
          )}
          <div className={css.lastElement} />
        </div> */}
        <SignupForm
          className={css.formWrapper}
          // onSubmit={handleSubmitSignup}
          onSubmit={() => {}}
          // inProgress={authInProgress}
          inProgress={false}
          // onOpenTermsOfService={() => this.setState({ tosModalOpen: true })}
          tab={'sigup-provider'}
          history={history}
          locationFrom={location?.state?.from || ''}
        />
      </form>
    </div>
  );
}

export default compose(withRouter)(BusinessFormContainer);
