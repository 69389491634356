import classNames from 'classnames';
import React, { useState } from 'react';
import css from './faqs.module.css';
import NamedLink from '../NamedLink/NamedLink';

function Faqs() {
  const [activeQuestions, setActiveQuestions] = useState([]);

  const toggleActiveQuestions = question => {
    setActiveQuestions(activeQuestions => {
      if (activeQuestions.includes(question)) {
        return activeQuestions.filter(activeQuestion => activeQuestion !== question);
      } else {
        return [...activeQuestions, question];
      }
    });
  };

  return (
    <div className={css.mainContainer}>
      <h3>Questions? We've got answers</h3>
      <div className={css.questionsContainer}>
        <div className={css.questionWrapper}>
          <span className={css.question}>Is there a setup fee?</span>
          <span
            className={classNames(css.answer, {
              [css.activeAnswer]: activeQuestions.includes('qs-1'),
            })}
          >
            There is NO setup fee with Activity Time for the Free Trial or Starter Plan.
          </span>
          <button type="button" onClick={() => toggleActiveQuestions('qs-1')}>
            {activeQuestions.includes('qs-1') ? 'I' : '+'}
          </button>
        </div>
        <div className={css.questionWrapper}>
          <span className={css.question}>Do I need a credit card to sign up?</span>
          <span
            className={classNames(css.answer, {
              [css.activeAnswer]: activeQuestions.includes('qs-2'),
            })}
          >
            <b>No</b> credit card is required. If you wish to try the extra publicity features
            during your trial you will be prompted to add a credit card. If you choose to add your
            credit card during your trial, your account can auto-renew as the monthly Build Plan at
            the end of your free trial. YOU always stay in control and can change plans, pause or
            delete this in your provider dashboard at any time during and/or after the trial{' '}
          </span>
          <button type="button" onClick={() => toggleActiveQuestions('qs-2')}>
            {activeQuestions.includes('qs-2') ? 'I' : '+'}
          </button>
        </div>
        <div className={css.questionWrapper}>
          <span className={css.question}>Can I try before I buy??</span>
          <span
            className={classNames(css.answer, {
              [css.activeAnswer]: activeQuestions.includes('qs-2'),
            })}
          >
            <b>YES!</b> Activity Time offers a huge FREE trial so you can use our platform at your
            own pace. We’re aiming to delight you, so once the trial is complete we hope you’ll stay
            on our platform because it offers you an easy-to-use, successful sales and marketing
            tool.
          </span>
          <button type="button" onClick={() => toggleActiveQuestions('qs-2')}>
            {activeQuestions.includes('qs-2') ? 'I' : '+'}
          </button>
        </div>

        <div className={css.questionWrapper}>
          <span className={css.question}>How many activities can I manage on Activity Time?</span>
          <span
            className={classNames(css.answer, {
              [css.activeAnswer]: activeQuestions.includes('qs-3'),
            })}
          >
            <b>Unlimited!</b> You can manage an unlimited number activities on Activity Time with
            all plans. With the Starter Plan you can list as many activities as you wish, we do
            point out the plan is limited to 3 activity categories: Classes, Camps and Workshops.
            The Build Plan and Grow Plan price structure encourages you to list as many activities
            as you have and includes all categories: Events, Clubs, Places to Visit, Birthdays,
            After-School Clubs, Breakfast Clubs, School Tours, In-School Programmes, Classes, Camps
            and Workshops.
          </span>
          <button type="button" onClick={() => toggleActiveQuestions('qs-3')}>
            {activeQuestions.includes('qs-3') ? 'I' : '+'}
          </button>
        </div>

        <div className={css.questionWrapper}>
          <span className={css.question}>How do I pick the right plan?</span>
          <span
            className={classNames(css.answer, {
              [css.activeAnswer]: activeQuestions.includes('qs-4'),
            })}
          >
            As your trial finishes you need to figure out which plan is best. To kick off we
            recommend the <b>Build Plan</b>, as it allows you to evaluate your monthly use and pause
            at any time. The <b>Grow Plan</b> offers the cheapest price overall, so once you are
            confident you love using our platform we suggest you pick that. The <b>Starter Plan</b>{' '}
            is perfect for those who run 1-2 activities (camps, classes or workshops) a year, paying
            a €2 service fee on completed bookings and no up-front cost.
          </span>
          <button type="button" onClick={() => toggleActiveQuestions('qs-4')}>
            {activeQuestions.includes('qs-4') ? 'I' : '+'}
          </button>
        </div>

        <div className={css.questionWrapper}>
          <span className={css.question}>What are the provider fees?</span>
          <span
            className={classNames(css.answer, {
              [css.activeAnswer]: activeQuestions.includes('qs-5'),
            })}
          >
            Here at Activity Time we offer a paid plan of €15 p/month or €150 p/year to post
            unlimited activities in 11 different categories and charge 1% per transaction to cover
            the software that successfully runs the platform and 1.9% + €0.25 for credit card
            processing fees. The benefit to you? A community of parents and teachers lining up to
            buy your activities and a place where you can do your admin quicker, increase visibility
            to your target market with a few clicks, hire staff when needed and increase your sales.
            Want the breakdown of fees… read on
            <span>
              <span>
                <b>No. 1: Credit Card Processing Fees</b>
              </span>
              As with all online transactions, our platform has credit card processing fees for each
              transaction that happens directly on Activity Time. The fee is a combination of a
              percentage of the total transaction price and a fixed fee. The fee depends on the
              country where your account is located and the country related to the payment card:
              <span>
                <span>
                  <u>Credit and Debit Cards:</u>
                </span>
                <span>1.9% + €0.25 for standard European Economic Area cards</span>
                <span>2.3% + €0.25 for premium European Economic Area cards </span>
                <span>2.9% + €0.25 for UK cards </span>
                <span>3.65% + €0.25 for international cards</span>
              </span>
            </span>
            <span>
              <span>
                <b>No. 2: Platform Transactional Fee</b>
              </span>
              The SaaS to run our marketplace platform incurs a 1% transactional value fee that
              includes on-going software development, increasing functionality, powerful stock
              management tools, secure data storage, dashboard maintenance and a flexible
              transaction engine.
            </span>
            <span>
              <span>
                <b>No. 3: Starter Plan Fees</b>
                This applies only to the Starter Plan, as this is a list n’ go type plan. The
                Starter Plan fee of €2 per-space-booked is deducted at source and the balance is
                immediately forwarded to the activity provider’s bank account. The other plans have
                a monthly or annual fee and therefore do not charge this fee.
              </span>
            </span>
          </span>
          <button type="button" onClick={() => toggleActiveQuestions('qs-5')}>
            {activeQuestions.includes('qs-5') ? 'I' : '+'}
          </button>
        </div>

        <div className={css.questionWrapper}>
          <span className={css.question}>Do parents pay fees?</span>
          <span
            className={classNames(css.answer, {
              [css.activeAnswer]: activeQuestions.includes('qs-6'),
            })}
          >
            <b>Yes.</b> Activity Time accepts a service fee of €1 per-space-booked through Express
            Booking as we believe parents benefit from using the platform and can positively share
            in its running costs.
          </span>
          <button type="button" onClick={() => toggleActiveQuestions('qs-6')}>
            {activeQuestions.includes('qs-6') ? 'I' : '+'}
          </button>
        </div>

        <div className={css.questionWrapper}>
          <span className={css.question}>Can I use my own website booking system?</span>
          <span
            className={classNames(css.answer, {
              [css.activeAnswer]: activeQuestions.includes('qs-7'),
            })}
          >
            <b>Yes!</b> Simply add a link to your own website when asked how you want customers to
            book. We understand you may have invested in creating a super booking system on your own
            website and want to reach more new customers through our platform. Our aim is to connect
            you with more parents and teachers to increase your sales. Could we encourage you to try
            our Express Booking option with at least one activity to try it out? We’d love to hear
            what you think. The benefits for listings using the Express Booking option will included
            being ranked higher in searches along with offering customers the quickest method of
            completing a booking.
          </span>
          <button type="button" onClick={() => toggleActiveQuestions('qs-7')}>
            {activeQuestions.includes('qs-7') ? 'I' : '+'}
          </button>
        </div>
        <div className={css.questionWrapper}>
          <span className={css.question}>What’s a Provider Dashboard?</span>
          <span
            className={classNames(css.answer, {
              [css.activeAnswer]: activeQuestions.includes('qs-7'),
            })}
          >
            <b>Great question!</b> Your provider dashboard is a control panel for all the activities
            you list on this site, along with your Business Profile and T&C’s. With each Express
            Booking activity you can see your spaces, no. of bookings, waiting list and sales
            figures. You can see who has booked, kid’s medical notes, emergency numbers plus call or
            email the people who booked with one click. You can edit, snooze / unsnoozed, delete and
            duplicate activities. And more… we invite you to come and check us out with your free
            trial.
          </span>
          <button type="button" onClick={() => toggleActiveQuestions('qs-7')}>
            {activeQuestions.includes('qs-7') ? 'I' : '+'}
          </button>
        </div>
        <div className={css.questionWrapper}>
          <span className={css.question}>How is my credit card information stored?</span>
          <span
            className={classNames(css.answer, {
              [css.activeAnswer]: activeQuestions.includes('qs-7'),
            })}
          >
            All payments are securely stored with our verified banking partner, Stripe.
          </span>
          <button type="button" onClick={() => toggleActiveQuestions('qs-7')}>
            {activeQuestions.includes('qs-7') ? 'I' : '+'}
          </button>
        </div>
        <div className={css.questionWrapper}>
          <span className={css.question}>What is Activity Time’s cancelation policy?</span>
          <span
            className={classNames(css.answer, {
              [css.activeAnswer]: activeQuestions.includes('qs-7'),
            })}
          >
            For the Starter Plan no cancelation policy is required, simply use our site when you
            want and pay a small service fee on activity spaces only when they are booked. You can
            delete or change your plan at any time. For the Build Plan, you sign up to a monthly
            contract, it will automatically renew unless you request to pause. The ‘pause’ option
            allows you to stop/start your monthly contract to suit your seasonal needs. Otherwise,
            cancel 30 days prior to renewal. For the Grow Plan you sign up to an annual contract, it
            will automatically renew unless requested to cancel 30 days prior to renewal. Unless
            deleted, we keep your account active/dormant so you can simply add new activities
            whenever suits you.
          </span>
          <button type="button" onClick={() => toggleActiveQuestions('qs-7')}>
            {activeQuestions.includes('qs-7') ? 'I' : '+'}
          </button>
        </div>
        <div className={css.questionWrapper}>
          <span className={css.question}>How do I create a listing?</span>
          <span
            className={classNames(css.answer, {
              [css.activeAnswer]: activeQuestions.includes('qs-7'),
            })}
          >
            Click ‘Add New Activity’ and follow the next few steps. Add a good description, give a
            suitable age range, include price(s) and then tell customers how you’d like them to book
            or make an enquiry.
            <br />
            Click publish.
            <br /> Your listing is live, woo!
          </span>
          <button type="button" onClick={() => toggleActiveQuestions('qs-7')}>
            {activeQuestions.includes('qs-7') ? 'I' : '+'}
          </button>
        </div>
        <div className={css.questionWrapper}>
          <span className={css.question}>Do I need photos?</span>
          <span
            className={classNames(css.answer, {
              [css.activeAnswer]: activeQuestions.includes('qs-7'),
            })}
          >
            Yes and the good thing is… we’re here to help. Using your own photos is ideal, parents
            want to know who you are and see your activities. If you are stuck simple poke around
            our library of images and you’re bound to find a few to fit. Next time you run an
            activity why not ask some loyal customers if you can take some photos to promote the
            activity. Some will say yes! Landscape style images work best of our platform, rather
            than portrait. You can edit your listings, including photos, at any time. Photos are
            required for listings (min 3 - 5 max) and your profile (min 3 - 5 max)
          </span>
          <button type="button" onClick={() => toggleActiveQuestions('qs-7')}>
            {activeQuestions.includes('qs-7') ? 'I' : '+'}
          </button>
        </div>
        <div className={css.questionWrapper}>
          <span className={css.question}>Should I set up a profile?</span>
          <span
            className={classNames(css.answer, {
              [css.activeAnswer]: activeQuestions.includes('qs-7'),
            })}
          >
            Having a business profile helps parents and teacher get to know you, your team and what
            makes your activities unique. It’s your best opportunity to shine on our platform. Plus
            it’s great for your SEO! Add a profile in 5 easy steps. Add a description of who you
            are, opening hours if relevant, contact details, photos and services you provide. Edit
            any time.
          </span>
          <button type="button" onClick={() => toggleActiveQuestions('qs-7')}>
            {activeQuestions.includes('qs-7') ? 'I' : '+'}
          </button>
        </div>
        <div className={css.questionWrapper}>
          <span className={css.question}>What about my refund policy?</span>
          <span
            className={classNames(css.answer, {
              [css.activeAnswer]: activeQuestions.includes('qs-7'),
            })}
          >
            Having clear terms and conditions is good for both you and your customer. We make this
            super easy. In 2 easy steps, choose from our T&C’s or add your own. Your refund policy
            is included in your T&C’s. Activity Time requires customer refund requests to be handled
            directly and swiftly by each activity provider. Publishing clear T&C’s will ensure the
            refund conversation can go smoothly.
          </span>
          <button type="button" onClick={() => toggleActiveQuestions('qs-7')}>
            {activeQuestions.includes('qs-7') ? 'I' : '+'}
          </button>
        </div>
        <div className={css.questionWrapper}>
          <span className={css.question}>What happens at the end of my free trial?</span>
          <span
            className={classNames(css.answer, {
              [css.activeAnswer]: activeQuestions.includes('qs-7'),
            })}
          >
            <b>Option 1:</b> <br />
            <u>No credit card:</u> Without any credit card attached to your account you will default
            to the Starter Plan. All listings that are not using the Express Booking option will get
            ‘snoozed’ and will not be visible to the public. You add more listings with this plan or
            change to a paid plan.
            <b>Option 2:</b> <br />
            <u>Credit card available:</u> With a credit card attached to your account you will
            default to the Build Plan. All listings from your free trial will stay seamlessly active
            for customers to view and book as before. You can change this plan at any time.
          </span>
          <button type="button" onClick={() => toggleActiveQuestions('qs-7')}>
            {activeQuestions.includes('qs-7') ? 'I' : '+'}
          </button>
        </div>
      </div>
      <div className={css.btndv}>
        <h3>Other Questions? We’d love to answer them...</h3>
        <NamedLink name="ContactUsPage">Contact Us</NamedLink>
      </div>
    </div>
  );
}

export default Faqs;
