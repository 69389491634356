import React from 'react';
import topRead1 from '../../assets/topRead1.jpg';
import topRead2 from '../../assets/topRead2.jpg';
import topRead3 from '../../assets/topRead3.jpg';
import topRead4 from '../../assets/topRead4.jpg';
import topRead5 from '../../assets/topRead5.jpg';
import topRead6 from '../../assets/topRead6.jpg';
import topRead7 from '../../assets/topRead7.jpg';

import css from './SectionIcons.module.css';
import { FormattedMessage } from 'react-intl';
import { createResourceLocatorString } from '../../util/routes';
import routeConfiguration from '../../routeConfiguration';
function SectionIcons(props) {
  const { history } = props;
  return (
    <>
      <div className={css.sectionTopRead}>
        {/* <div>
          <h1 className={css.topReadtitle}>
            <FormattedMessage id="SectionTopRead.sectionTitle" />
          </h1>
        </div>
        <div className={css.topReadBody}>
          <div className={css.sectionContainer}>
            <img src={topRead1} alt="1" />
            <FormattedMessage id="SectionTopRead.read1" />
          </div>
          <div className={css.sectionContainer}>
            <img src={topRead2} alt="2" />
            <FormattedMessage id="SectionTopRead.read2" />
          </div>
          <div className={css.sectionContainer}>
            <img src={topRead3} alt="3" />
            <FormattedMessage id="SectionTopRead.read3" />
          </div>
        </div>
        <div>
          <a
            href="https://www.activity-time.com"
            target="_blank"
            className={`${css.bloga} ${css.ourBlog}`}
          >
            More on Our Blog
          </a>
        </div> */}
        <div className={css.topBody2title}>
          <h2>
            <FormattedMessage id="SectionTopRead.body2Title" />
          </h2>
        </div>
        <div className={css.topReadBody2}>
          <div className={css.sectionContainer2}>
            <div className={css.imgRow}>
              <img src={topRead4} alt="1" className={css.img1} />
              <img src={topRead5} alt="1" className={css.img2} />
            </div>
            <div className={css.body2Title}>
              <p className={css.body2Title2}>
                <FormattedMessage id="SectionTopRead.body2Title1" />
              </p>
            </div>
            <div className={css.textDivs}>
              <p>
                Discover the best kids activities where you live, teach or enjoy holidays in
                Ireland. Search by: <b>Location, Activity, Dates and Age Range.</b>
              </p>
              <p>
                Activity Time is designed to make your life easier when planning and coordinating
                kids activities at home or in school. Save hours!
              </p>
              <p>
                Find kids camps, classes, clubs, events, birthdays, school tours, in-school
                programmes, after-school clubs, breakfast clubs, days out and workshops.
              </p>
              <p>
                Activity Time partners with kids activity providers who put love and energy into
                their businesses so your kids grow and thrive.
              </p>
            </div>
            <div className={css.browseActivities}>
              <button
                onClick={() =>
                  history.push(
                    createResourceLocatorString('SearchPage', routeConfiguration(), {}, {})
                  )
                }
                className={css.ourBlog}
              >
                Browse Activities
              </button>
            </div>
          </div>
          <div className={css.sectionContainer2}>
            <div className={css.imgRow}>
              <img src={topRead6} alt="2" className={css.img2} />
              <img src={topRead7} alt="2" className={css.img1} />
            </div>
            <div className={css.body2Title}>
              <p className={css.body2Title2}>
                {' '}
                <FormattedMessage id="SectionTopRead.body2Title2" />
              </p>
            </div>
            <div className={css.textDivs}>
              <p>
                Activity Time partners with kids activity providers and creates a platform to reach
                new parents and teachers in their county and beyond.
              </p>
              <p>
                Achieving 'SOLD OUT' for camps, classes, birthdays and events etc doesn't have to be
                so hard.
              </p>
              <p>
                Your customers want to book more activities, they just need to be able to find,
                share and book them easier.
              </p>
              <p>
                Our partners are generating more income and we aim to help your business grow and
                thrive.
              </p>
              <p>
                Activity Time saves you time finding customers so you can spend more time running
                great activities.
              </p>
            </div>
            <div className={css.browseActivities}>
              <button
                onClick={() =>
                  history.push(
                    createResourceLocatorString('ListMyBusinessPage', routeConfiguration(), {}, {})
                  )
                }
                className={css.ourBlog}
              >
                Learn More
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SectionIcons;
